// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/SelectInstance.css */

/* Estilização geral */
.container {
    max-width: 1200px;
  }
  
  /* Estilização dos cards de instância */
  .instance-card {
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .instance-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .whatsapp-icon {
    color: #25D366;
    font-size: 50px;
  }
  
  /* Efeito de seleção (ripple) - opcional */
  .instance-card:active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(37, 211, 102, 0.3);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    animation: ripple 0.6s linear;
    pointer-events: none;
    z-index: 1;
  }
  
  @keyframes ripple {
    to {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }
  
  /* Responsividade */
  @media (max-width: 576px) {
    .whatsapp-icon {
      font-size: 40px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/SelectInstance.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC,sBAAsB;AACtB;IACI,iBAAiB;EACnB;;EAEA,uCAAuC;EACvC;IACE,eAAe;IACf,2CAA2C;EAC7C;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA,0CAA0C;EAC1C;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,mCAAmC;IACnC,kBAAkB;IAClB,yCAAyC;IACzC,6BAA6B;IAC7B,oBAAoB;IACpB,UAAU;EACZ;;EAEA;IACE;MACE,yCAAyC;MACzC,UAAU;IACZ;EACF;;EAEA,mBAAmB;EACnB;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":["/* src/pages/SelectInstance.css */\n\n/* Estilização geral */\n.container {\n    max-width: 1200px;\n  }\n  \n  /* Estilização dos cards de instância */\n  .instance-card {\n    cursor: pointer;\n    transition: transform 0.3s, box-shadow 0.3s;\n  }\n  \n  .instance-card:hover {\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n  }\n  \n  .whatsapp-icon {\n    color: #25D366;\n    font-size: 50px;\n  }\n  \n  /* Efeito de seleção (ripple) - opcional */\n  .instance-card:active::after {\n    content: '';\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 300%;\n    height: 300%;\n    background: rgba(37, 211, 102, 0.3);\n    border-radius: 50%;\n    transform: translate(-50%, -50%) scale(0);\n    animation: ripple 0.6s linear;\n    pointer-events: none;\n    z-index: 1;\n  }\n  \n  @keyframes ripple {\n    to {\n      transform: translate(-50%, -50%) scale(1);\n      opacity: 0;\n    }\n  }\n  \n  /* Responsividade */\n  @media (max-width: 576px) {\n    .whatsapp-icon {\n      font-size: 40px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
