// src/pages/ContactsList.js

import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './ContactsList.css';

function ContactsList({
  contacts,
  selectedContact,
  onSelectContact,
  loadMoreContacts,
  hasMoreContacts,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredContacts = contacts.filter((contact) =>
    (contact.pushName || contact.formattedRemoteJid || contact.remoteJid)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const renderContact = (contact) => {
    if (!contact) return null; // Safeguard if contact is null

    return (
      <div
        key={contact.remoteJid}
        className={`contact-item ${
          selectedContact && selectedContact.remoteJid === contact.remoteJid
            ? 'selected'
            : ''
        }`}
        onClick={() => onSelectContact(contact)}
      >
        <img
          src={
            contact.profilePicUrl ||
            `https://ui-avatars.com/api/?name=${encodeURIComponent(
              contact.pushName || contact.formattedRemoteJid || contact.remoteJid
            )}&background=random`
          }
          alt={contact.pushName || contact.formattedRemoteJid || contact.remoteJid}
          className="contact-avatar"
        />
        <div className="contact-info">
          <h6>{contact.pushName || contact.formattedRemoteJid || contact.remoteJid}</h6>
          <p className="last-message">{contact.lastMessage}</p>
          <small className="last-message-time">
            {new Date(contact.lastMessageTimestamp * 1000).toLocaleString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </small>
        </div>
        {contact.unreadMessages > 0 && (
          <span className="unread-badge">{contact.unreadMessages}</span>
        )}
      </div>
    );
  };

  return (
    <div className="contacts-list" id="contactsScrollableDiv">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Pesquisar contato..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>
      <InfiniteScroll
        dataLength={filteredContacts.length}
        next={loadMoreContacts}
        hasMore={hasMoreContacts}
        loader={<h4>Carregando...</h4>}
        scrollableTarget="contactsScrollableDiv"
      >
        {filteredContacts.map((contact) => renderContact(contact))}
      </InfiniteScroll>
    </div>
  );
}

export default ContactsList;
