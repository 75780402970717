// src/pages/SelectInstance.js

import React, { useEffect, useState } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa'; // Ícone do WhatsApp
import { motion } from 'framer-motion'; // Para animações
import './SelectInstance.css'; // Importar arquivo de estilos

function SelectInstance() {
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const res = await api.get('/instances');
        setInstances(res.data);
        setLoading(false);
      } catch (err) {
        console.error('Erro ao buscar instâncias:', err);
        setError('Erro ao buscar instâncias.');
        setLoading(false);
      }
    };

    fetchInstances();
  }, []);

  const handleSelect = (instance) => {
    console.log('Selected Instance:', instance); // Debug
    // Armazenar no localStorage
    localStorage.setItem('instanceId', instance.id);
    localStorage.setItem('instanceName', instance.name);

    // Verificar armazenamento
    console.log('Instance ID armazenado:', localStorage.getItem('instanceId'));
    console.log('Instance Name armazenado:', localStorage.getItem('instanceName'));

    navigate('/chat');
  };

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  if (instances.length === 0) {
    return (
      <div className="container mt-5 text-center">
        <h3>Nenhuma instância encontrada.</h3>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Selecione uma Instância do WhatsApp</h2>
      <div className="row justify-content-center">
        {instances.map((instance) => (
          <motion.div
            key={instance.id}
            className="col-md-4 col-sm-6 mb-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className="card instance-card h-100 shadow-sm"
              onClick={() => handleSelect(instance)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSelect(instance);
              }}
            >
              <div className="card-body text-center">
                <FaWhatsapp className="whatsapp-icon mb-3" />
                <h5 className="card-title">{instance.name}</h5>
              </div>
              <div className="card-footer text-center bg-transparent">
                <small className="text-muted">Clique para selecionar</small>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default SelectInstance;
