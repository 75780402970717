// src/pages/Chat.js

import React, { useEffect, useState, useCallback, useRef } from 'react';
import api from '../services/api';
import ContactsList from './ContactsList';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import './Chat.css';
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { FaShareAlt, FaHome, FaSignOutAlt, FaPlus } from 'react-icons/fa'; // Import FaPlus para novo contato

function Chat() {
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messagesPage, setMessagesPage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [contactsPage, setContactsPage] = useState(1);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [audioAllowed, setAudioAllowed] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [input, setInput] = useState('');

  // Novo estado para modal de novo contato
  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);
  const [newContactNumber, setNewContactNumber] = useState('');

  const instanceId = localStorage.getItem('instanceId');
  const instanceName = localStorage.getItem('instanceName');

  const socketRef = useRef(null);
  const selectedContactRef = useRef(selectedContact);

  useEffect(() => {
    selectedContactRef.current = selectedContact;
  }, [selectedContact]);

  const notificationAudioRef = useRef(null);

  useEffect(() => {
    notificationAudioRef.current = new Audio('https://enviobot.com.br/notificao.mp3');
    notificationAudioRef.current.volume = 1.0;
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const resUser = await api.get('/users/profile');
        setUser(resUser.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setLoading(false);
      }
    };

    fetchUser();

    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (instanceId && instanceName) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [instanceId, instanceName]);

  const initializeSocket = () => {
    socketRef.current = io('https://conversasback.enviobot.com.br', {
      auth: {
        token: localStorage.getItem('token'),
        instanceId: instanceId,
        instanceName: instanceName,
      },
    });

    socketRef.current.on('connect', () => {
      console.log('Conectado ao Socket.io');
    });

    socketRef.current.on('new_message', (message) => {
      console.log('Received new_message:', message);
      const remoteJid = message.key.remoteJid;
      const isGroup = remoteJid.endsWith('@g.us');
      const participantJid = isGroup ? message.key.participant : null;

      let participantName = '';
      if (isGroup) {
        participantName = message.groupName || remoteJid;
      } else {
        participantName = message.pushName || remoteJid;
      }

      if (selectedContactRef.current && remoteJid === selectedContactRef.current.remoteJid) {
        setMessages((prev) => [message, ...prev]);
      }

      setContacts((prevContacts) => {
        const contactIndex = prevContacts.findIndex(
          (contact) => contact.remoteJid === remoteJid
        );

        if (contactIndex !== -1) {
          const updatedContacts = [...prevContacts];
          const [contact] = updatedContacts.splice(contactIndex, 1);
          const newUnreadMessages =
            (contact.unreadMessages || 0) + (message.key.fromMe ? 0 : 1);

          const updatedContact = {
            ...contact,
            lastMessage: extractMessageContent(message),
            unreadMessages: newUnreadMessages,
            lastMessageTimestamp: message.messageTimestamp,
            pushName: isGroup
              ? message.groupName || contact.pushName || contact.remoteJid
              : contact.pushName || contact.remoteJid,
          };
          updatedContacts.unshift(updatedContact);
          return updatedContacts;
        } else {
          const newContact = {
            id: remoteJid,
            remoteJid: remoteJid,
            formattedRemoteJid: remoteJid,
            pushName: isGroup ? message.groupName || remoteJid : participantName || remoteJid,
            isGroup: isGroup,
            lastMessage: extractMessageContent(message),
            unreadMessages: message.key.fromMe ? 0 : 1,
            lastMessageTimestamp: message.messageTimestamp,
          };
          return [newContact, ...prevContacts];
        }
      });

      if (
        !message.key.fromMe &&
        (!selectedContactRef.current || remoteJid !== selectedContactRef.current.remoteJid)
      ) {
        showNotification(message, isGroup, participantName);
        if (audioAllowed && notificationAudioRef.current) {
          notificationAudioRef.current.currentTime = 0;
          notificationAudioRef.current.play().catch((error) => {
            console.error('Erro ao reproduzir o áudio de notificação:', error);
          });
        }
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log('Desconectado do Socket.io');
    });

    socketRef.current.on('connect_error', (err) => {
      console.error('Erro de conexão do Socket.io:', err.message);
    });
  };

  const extractMessageContent = (message) => {
    const isGroup = message.key.remoteJid.endsWith('@g.us');
    const participantName = isGroup
      ? message.pushName || message.key.participant || ''
      : '';

    let content = '';

    switch (message.messageType) {
      case 'conversation':
        content = message.message.conversation;
        break;
      case 'imageMessage':
        content = message.message.imageMessage.caption || '[Imagem]';
        break;
      case 'audioMessage':
        content = '[Áudio]';
        break;
      case 'videoMessage':
        content = '[Vídeo]';
        break;
      default:
        content = '[Mensagem]';
        break;
    }

    if (isGroup && participantName) {
      return `${participantName}: ${content}`;
    }

    return content;
  };

  const showNotification = (message, isGroup, participantName) => {
    let title = '';
    if (isGroup) {
      const group = contacts.find(
        (contact) => contact.remoteJid === message.key.remoteJid
      );
      const groupName = group ? group.pushName : 'Grupo';
      title = participantName
        ? `${participantName} no ${groupName}`
        : groupName;
    } else {
      title = message.pushName || message.key.remoteJid;
    }

    const body = extractMessageContent(message);

    toast.info(
      <div>
        <strong>{title}</strong>
        <div>{body}</div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const fetchContacts = useCallback(
    async (page = 1) => {
      try {
        const resContacts = await api.get('/contacts', {
          params: {
            page,
            limit: 20,
          },
        });
        if (resContacts.data.length > 0) {
          const formattedContacts = resContacts.data.map((contact) => {
            const isGroup = contact.remoteJid.endsWith('@g.us');
            return {
              ...contact,
              isGroup: isGroup,
              pushName: isGroup
                ? contact.groupName || contact.pushName || null
                : contact.pushName || null,
            };
          });

          if (page === 1) {
            setContacts(formattedContacts);
          } else {
            setContacts((prev) => {
              const newContacts = formattedContacts.filter(
                (newContact) =>
                  !prev.some(
                    (contact) => contact.remoteJid === newContact.remoteJid
                  )
              );
              return [...prev, ...newContacts];
            });
          }
        } else {
          setHasMoreContacts(false);
        }
      } catch (error) {
        console.error('Erro ao carregar contatos:', error);
      }
    },
    []
  );

  useEffect(() => {
    fetchContacts(1);
  }, [fetchContacts]);

  const loadMoreContacts = () => {
    if (hasMoreContacts) {
      const nextPage = contactsPage + 1;
      fetchContacts(nextPage);
      setContactsPage(nextPage);
    }
  };

  const fetchMessages = useCallback(
    async (page = 1) => {
      if (!selectedContact) return;
      try {
        const resMessages = await api.get('/messages', {
          params: {
            page,
            limit: 20,
            remoteJid: selectedContact.remoteJid,
          },
        });

        if (resMessages.data.length > 0) {
          const processedMessages = resMessages.data.map((msg) => {
            if (!msg.key) {
              msg.key = {};
            }
            if (typeof msg.key.fromMe === 'undefined') {
              msg.key.fromMe = msg.fromMe ?? false;
            }
            return msg;
          });

          if (page === 1) {
            setMessages(processedMessages);
          } else {
            setMessages((prev) => [...prev, ...processedMessages]);
          }
        } else {
          setHasMoreMessages(false);
        }

        setContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact.remoteJid === selectedContact.remoteJid
              ? { ...contact, unreadMessages: 0 }
              : contact
          )
        );
      } catch (error) {
        console.error('Erro ao carregar mensagens:', error);
      }
    },
    [selectedContact]
  );

  useEffect(() => {
    setMessages([]);
    setMessagesPage(1);
    setHasMoreMessages(true);
    if (selectedContact) {
      fetchMessages(1);
    }
  }, [selectedContact, fetchMessages]);

  const loadMoreMessages = () => {
    if (hasMoreMessages) {
      const nextPage = messagesPage + 1;
      fetchMessages(nextPage);
      setMessagesPage(nextPage);
    }
  };

  const sendMessage = async (text, mediaContent = null, mediaType = null) => {
    if ((text.trim() || mediaContent) && selectedContact) {
      try {
        let type = 'text';
        if (mediaType === 'image') type = 'anexo';
        else if (mediaType === 'video') type = 'video';
        else if (mediaType === 'audio') type = 'audio';
        else if (mediaType === 'pdf') type = 'pdf';

        const payload = {
          remoteJid: selectedContact.remoteJid,
          content: mediaContent || text,
          type: type,
        };

        const response = await api.post('/messages/send', payload);

        if (response.status === 200) {
          const responseData = response.data.data;
          const sentMediaType = response.data.mediaType;

          const newMessage = {
            id: responseData.id || Date.now().toString(),
            key: { fromMe: true, remoteJid: selectedContact.remoteJid },
            messageType:
              sentMediaType === 'Texto' ? 'conversation' : sentMediaType.toLowerCase(),
            message:
              sentMediaType === 'Texto'
                ? { conversation: text }
                : sentMediaType === 'Anexo'
                ? { imageMessage: { caption: text, mimetype: 'image/png', url: mediaContent } }
                : sentMediaType === 'Áudio'
                ? { audioMessage: { url: mediaContent, mimetype: 'audio/mpeg' } }
                : sentMediaType === 'PDF'
                ? { documentMessage: { caption: text, mimetype: 'application/pdf', url: mediaContent } }
                : sentMediaType === 'Vídeo'
                ? { videoMessage: { caption: text, mimetype: 'video/mp4', url: mediaContent } }
                : { conversation: text },
            messageTimestamp: Math.floor(Date.now() / 1000),
            pushName: user.name || user.email,
            participant: user.jid || '',
          };

          console.log('Mensagem adicionada ao estado de mensagens:', newMessage);

          setContacts((prevContacts) => {
            const contactIndex = prevContacts.findIndex(
              (contact) => contact.remoteJid === selectedContact.remoteJid
            );

            if (contactIndex !== -1) {
              const updatedContacts = [...prevContacts];
              const [contact] = updatedContacts.splice(contactIndex, 1);

              const updatedContact = {
                ...contact,
                lastMessage: extractMessageContent(newMessage),
                lastMessageTimestamp: newMessage.messageTimestamp,
              };
              updatedContacts.unshift(updatedContact);
              return updatedContacts;
            } else {
              const newContact = {
                id: selectedContact.remoteJid,
                remoteJid: selectedContact.remoteJid,
                formattedRemoteJid: selectedContact.remoteJid,
                pushName: selectedContact.pushName || selectedContact.remoteJid,
                isGroup: selectedContact.isGroup,
                lastMessage: extractMessageContent(newMessage),
                unreadMessages: 0,
                lastMessageTimestamp: newMessage.messageTimestamp,
              };
              return [newContact, ...prevContacts];
            }
          });

          toast.success('Mensagem enviada com sucesso.');
        } else {
          toast.error('Falha ao enviar a mensagem.');
        }
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error.response?.data || error.message);
        toast.error('Falha ao enviar a mensagem. Tente novamente.');
      }
    } else {
      toast.error('Preencha o campo de mensagem ou selecione uma mídia.');
    }
  };

  const handleEnableAudio = () => {
    if (notificationAudioRef.current) {
      notificationAudioRef.current
        .play()
        .then(() => {
          setAudioAllowed(true);
          notificationAudioRef.current.pause();
          notificationAudioRef.current.currentTime = 0;
        })
        .catch((error) => {
          console.error('Erro ao tentar desbloquear áudio:', error);
        });
    }
  };

  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleShareContact = (contact) => {
    if (contact) {
      const contactNumber = getPhoneNumber(contact.remoteJid);
      setInput(`Contato: ${contactNumber}`);
      closeShareModal();
      toast.success(`Contato ${contactNumber} compartilhado!`);
    }
  };

  const isGroupChat = selectedContact ? selectedContact.isGroup : false;
  const getPhoneNumber = (remoteJid) => {
    if (!remoteJid) return '';
    return remoteJid.split('@')[0];
  };

  // Funções para novo contato
  const openNewContactModal = () => {
    setIsNewContactModalOpen(true);
  };

  const closeNewContactModal = () => {
    setIsNewContactModalOpen(false);
    setNewContactNumber('');
  };

  const startNewChat = () => {
    const number = newContactNumber.replace(/\D/g, ''); // Remover caracteres não numéricos
    if (!number) {
      toast.error('Por favor, insira um número válido.');
      return;
    }

    // Cria um "contato temporário"
    const tempContact = {
      remoteJid: `${number}@s.whatsapp.net`,
      pushName: `+${number}`,
      isGroup: false,
    };

    setSelectedContact(tempContact);
    closeNewContactModal();
  };

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-container d-flex">
      <div className="vertical-menu">
        <button
          className="menu-button"
          onClick={() => window.location.href = 'https://enviobot.com.br/home'}
          aria-label="Início"
          title="Início"
        >
          <FaHome className="menu-icon" />
          Início
        </button>
        <button
          className="menu-button"
          onClick={() => {
            if (window.confirm('Tem certeza que deseja sair?')) {
              localStorage.removeItem('token');
              localStorage.removeItem('instanceId');
              localStorage.removeItem('instanceName');
              window.location.href = 'https://enviobot.com.br/';
            }
          }}
          aria-label="Sair"
          title="Sair"
        >
          <FaSignOutAlt className="menu-icon" />
          Sair
        </button>
      </div>

      <div className="contacts-section">
        <div className="contacts-header d-flex justify-content-between align-items-center p-2">
          <h5>Contatos</h5>
          <button className="btn btn-sm btn-primary" onClick={openNewContactModal}>
            <FaPlus /> Novo Contato
          </button>
        </div>
        <ContactsList
          contacts={contacts}
          selectedContact={selectedContact}
          onSelectContact={setSelectedContact}
          loadMoreContacts={loadMoreContacts}
          hasMoreContacts={hasMoreContacts}
        />
      </div>

      <div className="chat-area flex-grow-1 d-flex flex-column">
        {!audioAllowed && (
          <div className="audio-permission-overlay">
            <div className="audio-permission-content">
              <p>Para receber notificações sonoras, clique no botão abaixo:</p>
              <button className="btn btn-primary" onClick={handleEnableAudio}>
                Ativar Som de Notificação
              </button>
            </div>
          </div>
        )}
        {selectedContact ? (
          <>
            <div className="chat-header p-3 border-bottom d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={
                    selectedContact.profilePicUrl ||
                    `https://ui-avatars.com/api/?name=${encodeURIComponent(
                      selectedContact.pushName || selectedContact.remoteJid
                    )}&background=random`
                  }
                  alt={selectedContact.pushName || selectedContact.remoteJid}
                  className="chat-header-avatar"
                />
                <div className="ms-3">
                  <h5 className="mb-0">
                    {selectedContact.pushName || selectedContact.formattedRemoteJid}
                    {isGroupChat && (
                      <span className="badge bg-secondary ms-2">Grupo</span>
                    )}
                  </h5>
                  {!isGroupChat && (
                    <small className="text-muted">
                      {getPhoneNumber(selectedContact.remoteJid)}
                    </small>
                  )}
                </div>
              </div>
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={openShareModal}
                title="Compartilhar Contato"
              >
                <FaShareAlt />
              </button>
            </div>
            <MessageList
              messages={messages}
              user={user}
              loadMoreMessages={loadMoreMessages}
              hasMoreMessages={hasMoreMessages}
              isGroupChat={isGroupChat}
            />
            <MessageInput
              onSend={sendMessage}
              phoneNumber={getPhoneNumber(selectedContact.remoteJid)}
              input={input}
              setInput={setInput}
            />
          </>
        ) : (
          <div className="no-chat d-flex align-items-center justify-content-center flex-grow-1">
            <h5>Selecione um contato ou adicione um novo para começar a conversar</h5>
          </div>
        )}
      </div>
      
      <Modal
        isOpen={isShareModalOpen}
        onRequestClose={closeShareModal}
        contentLabel="Compartilhar Contato"
        ariaHideApp={false}
        className="modal-dialog"
        overlayClassName="modal-backdrop"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Compartilhar Contato</h5>
            <button type="button" className="btn-close" onClick={closeShareModal}></button>
          </div>
          <div className="modal-body">
            <ContactsList
              contacts={contacts}
              selectedContact={selectedContact}
              onSelectContact={(contact) => handleShareContact(contact)}
              loadMoreContacts={loadMoreContacts}
              hasMoreContacts={hasMoreContacts}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeShareModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal para adicionar novo contato (apenas iniciar chat com número) */}
      <Modal
        isOpen={isNewContactModalOpen}
        onRequestClose={closeNewContactModal}
        contentLabel="Novo Contato"
        ariaHideApp={false}
        className="modal-dialog"
        overlayClassName="modal-backdrop"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Iniciar Nova Conversa</h5>
            <button type="button" className="btn-close" onClick={closeNewContactModal}></button>
          </div>
          <div className="modal-body">
            <p>Insira o número de telefone (com DDD, ex: 5583981342657):</p>
            <input
              type="text"
              value={newContactNumber}
              onChange={(e) => setNewContactNumber(e.target.value)}
              className="form-control"
              placeholder="Digite o número com DDD"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={startNewChat}>
              Iniciar Chat
            </button>
            <button className="btn btn-secondary" onClick={closeNewContactModal}>
              Cancelar
            </button>
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default Chat;
