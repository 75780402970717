import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Chat from './pages/Chat';
import SelectInstance from './pages/SelectInstance';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem('token', token);

      // Remove o token da URL
      const cleanURL = location.pathname;
      window.history.replaceState({}, document.title, cleanURL);

      // Redireciona para a seleção de instância
      navigate('/select-instance', { replace: true });
    }
  }, [location, navigate]);

  return (
    <Routes>
      {/* Redireciona a rota raiz para /login */}
      <Route path="/" element={<Navigate to="/login" replace />} />
      
      {/* Rotas públicas */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* Rota para selecionar instância */}
      <Route 
        path="/select-instance"
        element={
          <PrivateRoute>
            <SelectInstance />
          </PrivateRoute>
        }
      />

      {/* Rota privada para o chat */}
      <Route 
        path="/chat" 
        element={
          <PrivateRoute>
            <Chat />
          </PrivateRoute>
        } 
      />
      
      {/* Adicione outras rotas conforme necessário */}
      
      {/* Rota para qualquer página não encontrada */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default App;
